// extracted by mini-css-extract-plugin
export var mvpWebDevBenefitsSection = "z_dK";
export var mvpWebDevBusinessTypeSection = "z_dG";
export var mvpWebDevCaseSection = "z_dN";
export var mvpWebDevClientQuotes = "z_dP";
export var mvpWebDevExpertiseSection = "z_dM";
export var mvpWebDevIndustriesSection = "z_dF";
export var mvpWebDevPracticesSection = "z_dL";
export var mvpWebDevPrimeSection = "z_dC";
export var mvpWebDevProcessSection = "z_dH";
export var mvpWebDevServicesSection = "z_dD";
export var mvpWebDevTechStackSection = "z_dJ";