// extracted by mini-css-extract-plugin
export var discoverWorksItem = "cF_s2";
export var discoverWorksItem__arrowLink = "cF_tf";
export var discoverWorksItem__bg = "cF_s5";
export var discoverWorksItem__clientImg = "cF_tj";
export var discoverWorksItem__clientName = "cF_tk";
export var discoverWorksItem__clientPosition = "cF_tl";
export var discoverWorksItem__gradientAfricar = "cF_tz";
export var discoverWorksItem__gradientBabel = "cF_tD";
export var discoverWorksItem__gradientCaker = "cF_ts";
export var discoverWorksItem__gradientDv = "cF_ty";
export var discoverWorksItem__gradientImpact = "cF_tn";
export var discoverWorksItem__gradientIx = "cF_tx";
export var discoverWorksItem__gradientMalleni = "cF_tp";
export var discoverWorksItem__gradientPmk = "cF_tv";
export var discoverWorksItem__gradientPmkDesktop = "cF_tw";
export var discoverWorksItem__gradientRew = "cF_tm";
export var discoverWorksItem__gradientTimebot = "cF_tC";
export var discoverWorksItem__gradientTradeaboat = "cF_tq";
export var discoverWorksItem__gradientTradervs = "cF_tr";
export var discoverWorksItem__gradientVaria = "cF_tB";
export var discoverWorksItem__gradientWowner = "cF_tt";
export var discoverWorksItem__header = "cF_s3";
export var discoverWorksItem__imageHover = "cF_s4";
export var discoverWorksItem__mobileImage = "cF_tg";
export var discoverWorksItem__results = "cF_s8";
export var discoverWorksItem__results_head = "cF_s9";
export var discoverWorksItem__results_num = "cF_tb";
export var discoverWorksItem__results_text = "cF_tc";
export var discoverWorksItem__results_textOnly = "cF_td";
export var discoverWorksItem__review = "cF_th";
export var discoverWorksItem__title = "cF_s6";
export var discoverWorksItem__wrapper = "cF_s7";